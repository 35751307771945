import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
// import LogoTitle from '../../assets/images/logo-s.png';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
// import Logo from "./Logo"

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = " Marlin".split("")
    const jobArray = "A Software".split("")
    const testArray = "Engineer".split("")

    useEffect (() => {
        setTimeout(() =>{
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])
    return (
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i,</span>
                <br />
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'m</span>

                {/* <img src={LogoTitle} alt="developer"/> */}
                <AnimatedLetters letterClass={letterClass}
                strArray={nameArray}
                idx={15}/>
                <br />
                <AnimatedLetters letterClass={letterClass}
                strArray={jobArray}

                idx={20}/>
                <br/>
                <AnimatedLetters letterClass={letterClass}
                strArray={testArray}

                idx={30}/>
                </h1>
                <h2>Fullstack Developer / JavaScript / Python</h2>
                <Link to="contact" className='flat-button'>CONTACT ME</Link>
            </div>
            {/* <Logo /> */}
        </div>
    )
}

export default Home
